import anime from 'animejs/lib/anime.es.js';
import * as $ from 'jquery';

const PROJECTS_PER_PAGE = 10;

export class ProjectsSec {

    public $nav: JQuery;
    public $section: JQuery;

    private _filter = '';
    private _pageIndex = 0;
    
    private _oldHeight = null;
    private _heightAnim = null;


    get filter() {
        return this._filter;
    }

    set filter( value ) {
        if ( value == this._filter ) return;
        this._filter = value;
        this.applyFilter();
    }

    get pageIndex() {
        return this._pageIndex;
    }

    prepareHeightTransition() {
        const $thumbs = $('.projects__thumbs');
        this._oldHeight = $thumbs.height();
    }

    completeHeightTransition( immediate = false ) {
        const $thumbs = $('.projects__thumbs');
        const newThumbsHeight = $thumbs.height();

        if (this._heightAnim) this._heightAnim.pause();

        if (immediate) return;

        this._heightAnim = anime({
            targets: $thumbs[0],
            height: [this._oldHeight, newThumbsHeight],
            duration: 1000,
            easing: 'easeOutQuad',
            complete: () => {
                $thumbs.css('height', '');
            }
        })
    }

    set pageIndex( value ) {

        let $projects = this.$section.find('.projects__item');
        let $activeProjects = $projects.filter('.projects__item--active');
        const totalPages = Math.ceil( $activeProjects.length / PROJECTS_PER_PAGE );

        this._pageIndex = value; 
        this.$section.attr('data-page-index', value);

        $projects.hide();
        $activeProjects.slice(0, (value+1)*PROJECTS_PER_PAGE).show();

        if (value >= totalPages-1 ) {
            this.$section.removeClass('projects--has-more-items');
        } else {
            this.$section.addClass('projects--has-more-items');
        }

    }
    
    constructor() {
        this.$nav = $('.project-nav');
        this.$section = $('.projects');

        this.initNav();
        this.initMoreBtn();

        this.pageIndex = 0;
    }

    applyFilter() {

        const value = this.filter;

        const IMG_SEL = '.projects__img';

        const ACTIVE_CLASS = 'projects__item--active';

        let $projects = this.$section.find('.projects__item');
        $projects.removeClass( ACTIVE_CLASS );

        let $filterShows = $projects.filter((i, e) => {

            if (!value) return true;
            let cats = $(e).data('category');
            return cats && cats.indexOf(value) != -1;

        }).addClass( ACTIVE_CLASS );

        let $filterHides = $projects.not( $filterShows );

        $projects.children(IMG_SEL).css({
            opacity: 0,
            transform: '',
        });

        this.prepareHeightTransition();

        $filterHides.css('display', 'none');
        $filterShows.css('display', '');

        this.completeHeightTransition( true );

        this.pageIndex = 0;

        anime({
            targets: $filterShows.children(IMG_SEL).toArray(),
            opacity: 1,
            /*scaleY: {
                value: [5, 1],
                easing: 'easeOutCubic',
                duration: 200,
            },*/
            //top: [-10, 0],
            duration: 300,
            easing: 'linear',
            delay: function() { return anime.random(0, 300); },
        });

        return;

        let tl = anime.timeline();

        tl.add({
            targets: $projects.children(IMG_SEL).toArray(),
            duration: 200,
            //scale: 1.2,
            //skew: function() { return anime.random(-15, 15); },
            //rotateY: 10,
            opacity: 0,
            easing: 'linear',
            complete: () => {

                $projects.children(IMG_SEL).css({
                    opacity: 0,
                    transform: '',
                });

                this.prepareHeightTransition();

                $filterHides.css('display', 'none');
                $filterShows.css('display', '');
    
                this.pageIndex = 0;

                this.completeHeightTransition( true );
            }
        }).add({
            targets: $filterShows.children(IMG_SEL).toArray(),
            opacity: 1,
            /*scaleY: {
                value: [10, 1],
                easing: 'easeOutCubic',
                duration: 400,
            },*/
            //top: [-10, 0],
            duration: 200,
            easing: 'linear',
            //delay: anime.stagger(100)
        });
       




    }

    initNav() {

        $('body').on('click', '.project-nav__link', (e) => {

            e.preventDefault();

            const $link = $(e.currentTarget);
            const filter = $link.data('filter');

            this.filter = filter;

        });

    }

    initMoreBtn() {

        const $btn = $('.projects__more-btn');

        $btn.on('click', (e) => {

            e.preventDefault();

            this.prepareHeightTransition();
            this.pageIndex++;
            this.completeHeightTransition();

        });

    }

}
import * as $ from 'jquery';
import { Swiper } from './sw';
import { urlparser } from 'modernizr';

export class Moodboard {

    $elem: JQuery;

    constructor( $elem ) {

        this.$elem = $elem;

        this.selectFirstImg();

        const swiper = new Swiper( $elem.find('.mood__board')[0] , { 
            loop: true,
            slidesPerView: 1, 
        
            // If we need pagination
            pagination: {
              el: $elem.find('.m-nav__bullets')[0],
            },
          
            // Navigation arrows
            navigation: {
              nextEl: $elem.find('.m-nav__right-btn')[0],
              prevEl: $elem.find('.m-nav__left-btn')[0],
            },
          
        });


        $elem.on('click', '.m-board__item', (e) => {
            this.selectItem( $(e.currentTarget) );
        });

    }

    selectFirstImg() {

        const $firstImg = this.$elem.find('.m-board__item:eq(0)');
        this.selectItem( $firstImg );

    }

    selectItem( $item ) {

        const $thumb = $('img', $item);

        this.$elem.find('.m-gallery__img')
            .data('id', $thumb.attr('data-id'))
            .attr('src', $thumb.attr('src'))
            .attr('srcset', $thumb.attr('srcset'));


    }


}
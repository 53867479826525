import * as $ from 'jquery';

const IMG_CLASS = 'face-tile__img';
const TILE_CLASS = 'face-tile__item';
const IMG_SEL = '.face-tile__img';
const TILE_SEL = '.face-tile__item';

export class Face {

    $e: JQuery;
	imgs: {src:string, srcset:string, sizes:string}[];

    constructor( $elem ) {
		var $e = this.$e = $elem;
		this.imgs = $e.find('img').toArray().map((e:HTMLImageElement) => ({
			src: e.src,
			srcset: e.srcset,
			sizes: e.sizes
		}));

		$e.children().remove();

		for (var i=0; i<4; i++) {
			var $tile = $(`<div class="${TILE_CLASS}" />`);	
			$e.append($tile);
		}
		 

		this.rotateTile(0);
		this.rotateTile(1);
		this.rotateTile(2);
		this.rotateTile(3);

		this.setTimeout();
    }

	handleShown() {
		this.$e.find(IMG_SEL).prev().remove();
	}

	setTimeout() {
		setTimeout(() => this.rotate(), 1500);
	}

	rotate() {
		var tileIndex = Math.floor(4*Math.random());
		this.rotateTile(tileIndex);
		this.setTimeout();
	}

	rotateTile(index) {
		var $tile = this.$e.find(TILE_SEL).eq(index);
		var img = this.imgs[Math.floor(this.imgs.length*Math.random())];
		
		var $img = $(`<img class="${IMG_CLASS}" src="${img.src}" srcset="${img.srcset}" sizes="${img.sizes}" alt="" />`);

		var $prev = $tile.find(IMG_SEL);
		$tile.append($img);

		if (!$prev.length) {
			return;
		}

		$img.css({
			opacity: 0
		}).animate({
			opacity: 1
		}, 500, () => this.handleShown());
	}

}


import * as $ from 'jquery';

const SCROLLBAR_W_PROP = '--scrollbar-jerk-w';

export class Page {

    protected $page;

    constructor() {

        window['page'] = this;

        $(() => this.handlePageReady());

    }

    get isMenuVisible() {

        return this.$page.hasClass('page--menu-active');

    }

    handlePageReady() {

        this.$page = $('.page');
        this.initMenuShowHide();

    }

    showMenu() {

        if (this.isMenuVisible) return;

        let offsetWidth = document.body.offsetWidth;
        this.$page.addClass('page--menu-active');
        document.documentElement.style.setProperty(SCROLLBAR_W_PROP, (document.body.offsetWidth - offsetWidth) + 'px');

    }

    hideMenu() {

        if (!this.isMenuVisible) return;

        this.$page.removeClass('page--menu-active');
        document.documentElement.style.setProperty(SCROLLBAR_W_PROP, '0');

    }

    initMenuShowHide() {

        $('.header__menu-btn').on('click', () => {
   
            this.isMenuVisible ? this.hideMenu() : this.showMenu();

        });

        $('.menu__link').on('click', () => {

            this.hideMenu();

        });
    }

}
import * as $ from 'jquery';
import * as Modernizr from 'modernizr';

const breakpoints = {
	'desk': 1287, // from design layout
	'mob': 360, // from design layout

	//'xl': 1800,
	'l': 1200,
	'm': 992,
	's': 900,
	'xs': 720,
    'xxs': 576,
	'xxxs': 360,
};


/**
	mqRule: '<xs', '>xl', etc
*/
export function mq( mqRule: string ): boolean {

    // true if empty rule
    if (!mqRule) return true;

    const condition = mqRule[0];

    if (condition == '>') return mqMin( mqRule.substr(1) );
    if (condition == '<') return mqMax( mqRule.substr(1) );

    throw new Error(`Invalid media queue rule "${mqRule}". Must start with ">" or "<".`);

}

export function mqMin( breakpointName: string, delta: number = 0 ): boolean {
    let bp = breakpoint( breakpointName ) + delta;
    return Modernizr.mq(`(min-width: ${bp}px)`);
}

export function mqMax( breakpointName: string, delta: number = 0 ): boolean {
    let bp = breakpoint( breakpointName ) + delta;
    return Modernizr.mq(`(max-width: ${bp}px)`);
}

export function breakpoint( name: string ) {
    return breakpoints[name];
}
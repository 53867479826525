import * as $ from 'jquery';
import { Page } from './page';
import { ProjectsSec } from './projects-sec';
import { debounce, throttle } from 'lodash';
import { mq, mqMax } from './utils';
import { Face } from './face';
import { Moodboard } from './moodboard';
import { trimStart, trimEnd } from 'lodash';

const ITEM_ACTIVE_CLASS = 'menu__item--active';

function parseUrl( urlStr ) {
    let url = new URL( urlStr );        
    let origin = url.origin;
    let path = trimEnd( url.pathname, '/' );
    let hash = trimStart(url.hash, '#');
    let hashBase = null;
    let hashPath = null;

    if (hash) {
        hash = hash.split('/');
        hashBase = hash[0] || null;
        hashPath = hash.slice(1).join('/') || null;
    }

    return [ origin + path, hashBase, hashPath ];
}



new (class HomePage extends Page {

    private projectsSec;

    protected $header: JQuery;
    protected $sections: JQuery;

    constructor() {
        super();
        
    }

    get headerHeight() {
        return this.$header.outerHeight();
    }

    get scrollTop() {
        return $('html')[0].scrollTop;
    }

    get scrollHeight() {
        return $('html')[0].scrollHeight;
    }

    handlePageReady() {
        super.handlePageReady();

        this.$header = $('.header');
        this.$sections = $('.page__section[data-menu-id]');

        new Moodboard( $('.mood') );
        new Face( $('.the-end__face') );
        this.projectsSec = new ProjectsSec();

        this.initNav();

        this.updateProjectNavVis();
        this.updateMenuSelection();

        $(document).on('scroll', throttle( () => this.handleScroll(), 100 ));
        $(window).on('resize', throttle( () => this.handleResize(), 100 ));
    }

    handleResize() {

        this.updateProjectNavVis();
        this.updateMenuSelection();

    }

    handleScroll() {

        this.updateProjectNavVis();
        this.updateMenuSelection();

    }

    initNav() {

        const [ currUrl, currSection, currFilter ] = parseUrl( location.href );        

        //$('.menu__nav > .menu__list > .menu__item > .menu__link')
        $('.menu__link').each( (i,elem) => {

            const $elem = $(elem);
            const [ url, hash, path ] = parseUrl( $elem.attr('href') );

            if (url != currUrl) {
                return;
            }

            const $item = $elem.closest('.menu__item');

            $item.attr('data-section', hash);
            $item.attr('data-filter', path || '');

        }).on('click', (e) => {

            const $elem = $(e.currentTarget);
            const $item = $elem.closest('.menu__item');

            const section = $item.data('section');
            const filter = $item.data('filter');

            //this link points to another page
            if (!section) {
                return;
            }

            e.preventDefault();

            this.goto( section, filter );

        });

        if (currSection == 'projects' && currFilter) {
            this.projectsSec.filter = currFilter;
        }

    }

    goto( section, filter = null ) {

        const $section = $(`#${section}`);
        
        if (!$section.length) {
            console.debug(`Section not found: ${section}`);
            return;
        }

        const sectionTop = $section.offset().top;

        if ( section == 'projects' ) {
            this.projectsSec.filter = filter;
        }

        $('html').animate({
                'scrollTop': sectionTop,
            }, 
            500, 
            () => this.updateMenuSelection()
        );

    }

    updateProjectNavVis() {

        const visBpoint = 's'; // if screen is smaller than this, navbar is invisible

        if ( mqMax(visBpoint) ) {
            this.projectsSec.$nav.css({ 'opacity': '' });
            return;
        }

        const projectsBottom = this.projectsSec.$section.offset().top + this.projectsSec.$section.outerHeight();

        this.projectsSec.$nav.css('opacity', this.scrollTop + this.headerHeight > projectsBottom ? 0 : '' );

    }

    updateMenuSelection() {

        
        const SCROLL_DELTA = 10; //why i need this?

        
        const scrollTopEdge = this.scrollTop + this.headerHeight;
        const htmlClientHeight = document.documentElement.clientHeight;
        const clipAreaHeight = htmlClientHeight - this.headerHeight;
        //const scrollBottomEdge = this.scrollTop + htmlClientHeight;
        const scrolledToBottom = this.scrollTop + htmlClientHeight + SCROLL_DELTA >= this.scrollHeight 
        
        let currentSection = null;
        
        if (scrolledToBottom) {
            currentSection = this.$sections.last()[0];
        } else {

            for (let section of this.$sections.toArray()) {
                const top = section.offsetTop;
                const bottom = section.offsetHeight + top;

                if ( bottom + SCROLL_DELTA >= scrollTopEdge ) {
                    currentSection = section;
                }

                if ( bottom > scrollTopEdge + clipAreaHeight/2 ) {
                    break;
                }

            }

        }


        let currentSectionId = $(currentSection).attr('data-menu-id');

        $('.menu__item').removeClass(ITEM_ACTIVE_CLASS);

        let sel = `.menu__item[data-section="${currentSectionId}"]`;
        if (currentSectionId == 'projects') {
            sel = `${sel}[data-filter="${this.projectsSec.filter}"], ${sel}.menu__item--root`;
        }

        $(sel).addClass(ITEM_ACTIVE_CLASS);

        history.replaceState(null, '', parseUrl(location.href)[0] + `#${currentSectionId}`);

    }


});
